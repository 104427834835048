import React from "react"
import styled from "styled-components"
import "./klaus-gridski.css"

const Row = styled.div`
  display: grid;
  grid-auto-rows: auto;
  height: 100%;
`

export const Grid = ({
  xl,
  l,
  bg,
  md,
  sm,
  col,
  xlgap,
  lgap,
  bggap,
  mdgap,
  smgap,
  gap,
  children,
}) => {
  let sizes = ""
  /* Grid responsive columns */
  if (xl) {
    sizes = `${sizes} xl-col-${xl}`
  }

  if (l) {
    sizes = `${sizes} l-col-${l}`
  }

  if (bg) {
    sizes = `${sizes} bg-col-${bg}`
  }

  if (md) {
    sizes = `${sizes} md-col-${md}`
  }

  if (sm) {
    sizes = `${sizes} sm-col-${sm}`
  }

  if (col) {
    sizes = `${sizes} col-${col}`
  }
  /* Grid space between */
  if (xlgap) {
    sizes = `${sizes} xl-gap-${xlgap}`
  }

  if (lgap) {
    sizes = `${sizes} l-gap-${lgap}`
  }

  if (bggap) {
    sizes = `${sizes} bg-gap-${bggap}`
  }

  if (mdgap) {
    sizes = `${sizes} md-gap-${mdgap}`
  }

  if (smgap) {
    sizes = `${sizes} sm-gap-${smgap}`
  }

  if (gap) {
    sizes = `${sizes} gap-${gap}`
  }

  return (
    <Row
      className={sizes}
      xl={xl}
      l={l}
      bg={bg}
      md={md}
      sm={sm}
      col={col}
      xlgap={xlgap}
      lgap={lgap}
      bggap={bggap}
      mdgap={mdgap}
      smgap={smgap}
      gap={gap}
    >
      {children}
    </Row>
  )
}

export const Col = ({
  xlspan,
  lspan,
  bgspan,
  mdspan,
  smspan,
  span,
  xlrow,
  lrow,
  bgrow,
  mdrow,
  smrow,
  row,
  display,
  xljump,
  ljump,
  bgjump,
  mdjump,
  smjump,
  jump,
  xlalign,
  lalign,
  bgalign,
  mdalign,
  smalign,
  align,
  xljustify,
  ljustify,
  bgjustify,
  mdjustify,
  smjustify,
  justify,
  custom,
  children,
}) => {
  let sizes = ""
  let pos = ""
  /* Columns */
  if (xlspan) {
    sizes = `${sizes}xl-grid-col-${xlspan}`
  }

  if (lspan) {
    sizes = `${sizes}l-grid-col-${lspan}`
  }

  if (bgspan) {
    sizes = `${sizes}bg-grid-col-${bgspan}`
  }

  if (mdspan) {
    sizes = `${sizes}md-grid-col-${mdspan}`
  }

  if (smspan) {
    sizes = `${sizes}sm-grid-col-${smspan}`
  }

  if (span) {
    sizes = `${sizes}grid-col-${span}`
  }
  /* Rows */
  if (xlrow) {
    sizes = `${sizes}xl-grid-row-${xlrow}`
  }

  if (lrow) {
    sizes = `${sizes}l-grid-row-${lrow}`
  }

  if (bgrow) {
    sizes = `${sizes}bg-grid-row-${bgrow}`
  }

  if (mdrow) {
    sizes = `${sizes}md-grid-row-${mdrow}`
  }

  if (smrow) {
    sizes = `${sizes}sm-grid-row-${smrow}`
  }

  if (row) {
    sizes = `${sizes}grid-row-${row}`
  }
  /* Add grid, flex, block ... */
  if (display) {
    pos = `${pos} ${display}`
  }
  /* grid span position of grid element */
  if (xljump) {
    pos = `${pos} xl-jump-${xljump}`
  }

  if (ljump) {
    pos = `${pos} l-jump-${ljump}`
  }

  if (bgjump) {
    pos = `${pos} bg-jump-${bgjump}`
  }

  if (mdjump) {
    pos = `${pos} md-jump-${mdjump}`
  }

  if (smjump) {
    pos = `${pos} sm-jump-${smjump}`
  }

  if (jump) {
    pos = `${pos} jump-${jump}`
  }
  /* flex/grid vertical positions */
  if (xlalign) {
    pos = `${pos} xl-v-${xlalign}`
  }

  if (lalign) {
    pos = `${pos} l-v-${lalign}`
  }

  if (bgalign) {
    pos = `${pos} bg-v-${bgalign}`
  }

  if (mdalign) {
    pos = `${pos} md-v-${mdalign}`
  }

  if (smalign) {
    pos = `${pos} sm-v-${smalign}`
  }

  if (smalign) {
    pos = `${pos} sm-v-${smalign}`
  }

  if (align) {
    pos = `${pos} v-${align}`
  }
  /* flex/grid horizontal positions */
  if (xljustify) {
    pos = `${pos} xl-h-${xljustify}`
  }

  if (ljustify) {
    pos = `${pos} l-h-${ljustify}`
  }

  if (bgjustify) {
    pos = `${pos} bg-h-${bgjustify}`
  }

  if (mdjustify) {
    pos = `${pos} md-h-${mdjustify}`
  }

  if (smjustify) {
    pos = `${pos} sm-h-${smjustify}`
  }

  if (justify) {
    pos = `${pos} h-${justify}`
  }

  if (custom) {
    custom = ` ${custom}`
  } else {
    custom = ""
  }

  return (
    <div
      className={`${sizes}${pos}${custom}`}
      xlspancol={xlspan}
      lspan={lspan}
      bgspan={bgspan}
      mdspan={mdspan}
      smspan={smspan}
      span={span}
      xlrow={xlrow}
      lrow={lrow}
      bgrow={bgrow}
      mdrow={mdrow}
      smrow={smrow}
      row={row}
      xljump={xljump}
      ljump={ljump}
      bgjump={bgjump}
      mdjump={mdjump}
      smjump={smjump}
      jump={jump}
      xlalign={xlalign}
      lalign={lalign}
      bgalign={bgalign}
      mdalign={mdalign}
      smalign={smalign}
      align={align}
      xljustify={xljustify}
      ljustify={ljustify}
      bgjustify={bgjustify}
      mdjustify={mdjustify}
      smjustify={smjustify}
      justify={justify}
    >
      {children}
    </div>
  )
}
