import React from "react"
import styled from "styled-components"

const QuoteStyle = styled.i`
  font-family: "PT Serif";
  color: var(--light-green);
  font-size: 2.3rem;
  max-width: 650px;
  display: block;
`

const Author = styled.p`
  font-family: "Lato";
  font-size: 1.7rem;
`

const QuoteWrap = styled.div`
  margin: 5rem 0;
  display: flex;
  align-items: flex-start;
  /* Width in PX > 768px */
  @media only screen and (min-width: 48em) {
    ::before {
      display: inline-block;
      content: "";
      border-top: 1.5px solid #1c3c2c;
      width: 5rem;
      margin: 2rem;
    }
  }
`

const Quote = ({ children, author }) => {
  return (
    <QuoteWrap className="content-visibility">
      <div>
        <QuoteStyle>{children}</QuoteStyle>
        <Author> {author} </Author>
      </div>
    </QuoteWrap>
  )
}

export default Quote
