import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"

import Layout from "../components/layout"
import { Section, Wrapper, Paragraph } from "../components/styles/container"
import { Grid, Col } from "../components/styles/grid"
import Quote from "../components/quote"
import Mail from "../components/mail"

import Upper from "../images/coaching_oben_cropped.jpg"
import UpperTab from "../images/coaching_oben_cropped.jpg"
import BackgroundLetter from "../images/icons/coaching.svg"
import Lower from "../images/coaching_unten_cropped.jpg"
import LowerTab from "../images/coaching_unten_cropped.jpg"

/******************************************************************************************* STYLED
 *************************************************************************************************/

const TransformImg = styled.img`
  /* Width in PX > 1920px */
  @media only screen and (min-width: 121em) {
    transform: translateX(40%);
  }
`

const Relative = styled.div`
  position: relative;
`

const LetterStyle = styled.img`
  max-height: 300px;
  opacity: 0.15;
  z-index: -1;
  position: absolute;
  bottom: -100px;
  right: 20px;
`

const SecondSection = styled(Section)`
  margin-top: 11rem;
  /* Width in PX > 1200px */
  /* ==== = LARGE = ==== */
  @media only screen and (min-width: 75em) {
    margin-top: 20rem;
  }
`

/******************************************************************************************** COACHING COMPONENT
 **************************************************************************************************/

const Coaching = () => {
  return (
    <Layout>
      <SEO title="Coaching" />
      {/* // ---------------------------------------------------------------------------FIRST SECTION ON MOBILE */}
      <Section className="appear l-disappear">
        <Wrapper className="flex">
          <Grid gap="7">
            <Col>
              <Relative>
                <h2>Coaching</h2>
                <h3>
                  Ein individuelles Coaching gibt Ihnen Professionalität und
                  Selbstsicherheit
                </h3>
                <Paragraph>
                  Coaching hat eine positive Wirkung, was zwischenzeitlich in
                  etlichen Studien nachgewiesen wurde. Ein individuelles
                  Coaching gibt Ihnen Professionalität und Selbstsicherheit.
                  Beim Coaching stehen Sie im Mittelpunkt. <br /> Coaching hilft
                  den Blickwinkel zu erweitern, die eigenen Bewertungen zu
                  hinterfragen. Wir schauen gemeinsam auf Ihr Anliegen und
                  entdecken bereits vorhandene Fähigkeiten und erweitern die
                  Handlungsoptionen. Oft ist es leichter die Perspektive zu
                  wechseln und Antworten zu finden, wenn jemand neutrales die
                  richtigen Fragen stellt.
                </Paragraph>
              </Relative>
            </Col>
            <Col>
              <div className="flex h-center l-h-start">
                <TransformImg
                  className="img-opacity v-img"
                  alt="Christina Pabst"
                  src={UpperTab}
                />
              </div>
            </Col>
          </Grid>
        </Wrapper>
      </Section>
      {/* // ---------------------------------------------------------------------------FIRST SECTION ON DESKTOP */}

      <Section className="disappear l-appear">
        <Wrapper className="flex">
          <Grid l="2" gap="7">
            <Col>
              <div className="flex h-center l-h-start">
                <TransformImg
                  className="img-opacity v-img"
                  alt="Christina Pabst"
                  src={Upper}
                />
              </div>
            </Col>
            <Col>
              <Relative>
                <h2>Coaching</h2>
                <h3>
                  Ein individuelles Coaching gibt Ihnen Professionalität und
                  Selbstsicherheit
                </h3>
                <LetterStyle src={BackgroundLetter} />
                <Paragraph>
                  Coaching hat eine positive Wirkung, was zwischenzeitlich in
                  etlichen Studien nachgewiesen wurde. Ein individuelles
                  Coaching gibt Ihnen Professionalität und Selbstsicherheit.
                  Beim Coaching stehen Sie im Mittelpunkt. <br /> Coaching hilft
                  den Blickwinkel zu erweitern, die eigenen Bewertungen zu
                  hinterfragen. Wir schauen gemeinsam auf Ihr Anliegen und
                  entdecken bereits vorhandene Fähigkeiten und erweitern die
                  Handlungsoptionen. Oft ist es leichter die Perspektive zu
                  wechseln und Antworten zu finden, wenn jemand neutrales die
                  richtigen Fragen stellt.
                </Paragraph>
              </Relative>
            </Col>
          </Grid>
        </Wrapper>
      </Section>

      {/* // ---------------------------------------------------------------------------SECOND SECTION */}

      <SecondSection className="content-visibility">
        <Wrapper className="flex">
          <Grid l="2" gap="7">
            <Col>
              <h2>Was beinhaltet Coaching?</h2>
              <Paragraph>
                Das Coaching wird auf Ihre individuellen Anforderungen
                zugeschnitten. Umfang, Inhalt und Ort stimmen wir genau auf Ihre
                Wünsche ab. Ihr Anliegen behandle ich stets streng vertraulich.{" "}
                <br />
                <br />
                Coachings können bei Ihnen, Outdoor, in Alsfeld oder natürlich
                auch online stattfinden.
              </Paragraph>
              <Quote author="Laotse">
                “Nur wer sein Ziel kennt, findet den Weg.”{" "}
              </Quote>
              <Mail />
            </Col>
            <Col>
              <div className="flex h-center l-h-start">
                <picture>
                  <source
                    className="v-img"
                    alt="Eine Nahaufnahme wie jemand sitzt"
                    srcset={LowerTab}
                    media="(max-width: 74.99em)"
                  />
                  <TransformImg
                    className="v-img"
                    alt="Eine Nahaufnahme wie jemand sitzt"
                    src={Lower}
                  />
                </picture>
              </div>
            </Col>
          </Grid>
        </Wrapper>
      </SecondSection>
    </Layout>
  )
}

export default Coaching
