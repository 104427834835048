import React from "react"
import styled from "styled-components"

const MailStyle = styled.a`
  display: flex;
  align-items: center;
  font-family: "PT Serif";
  color: var(--light-green);
  font-style: italic;
  transition: ease-in-out 0.2s;
`

const Btn = styled.div`
  border: 2px solid var(--light-green);
  padding: 1.5rem 1rem;
  width: fit-content;
  border-radius: 5px;
  transition: ease-in-out 0.2s;
  /* Width in PX > 1200px */
  @media only screen and (min-width: 75em) {
    :hover {
      border: 2px solid var(--dark-green);
      a {
        color: var(--dark-green);
      }
    }
  }
`

const Mail = () => (
  <Btn>
    <MailStyle
      title="Christina Pabst E-Mailadresse"
      href="mailto:hallo@christinapabst.de"
      target="_blank"
    >
      Jetzt anfragen
    </MailStyle>
  </Btn>
)

export default Mail
